/* You can add global styles to this file, and also import other style files */
// @import '../node_modules/@appkit4/styles/appkit.min.css';
@import '../node_modules/@appkit4/styles/themes/appkit.orange.min.css';
@import '../node_modules/@appkit4/styles/scss/_variables.scss';


:root {
    --neutral-01: #FFFFFF;
    --light-grey: #F3F3F3;
    --neutral-02: #E8E8E8;
    --primary-orange: #D04A02;
    // --neutral-12: #747474;
    --neutral-14: #696969;
    --neutral-20: #252525;
    --success: #21812d;
}

.ap-container {
    max-width: 100%
}

h2 {
    font-size: 1.5rem;
}

h3 {
    font-size: 1.25rem;
}

h2,
h3 {
    // padding-bottom: 1rem;
    margin: 1rem 0;
}

h5 {
    padding: 0;
    margin: 0;
    font-weight: normal;
}

hr {
    margin: 1.5rem 0;
    border-top: 1px solid $color-background-border;

    &.dashed {
        border-top: 2px dashed var(--color-text-heading);
    }

    &.strong {
        border-top-width: 3px;
        border-top-color: var(--color-text-heading);
    }

    &.profile {
        margin: 1.5rem 0 0 0;
        border-top: 1px solid var(--neutral-20);
    }
}